import { CreateControllerFn, ControllerParams } from '@wix/yoshi-flow-editor';
import { getBaseUrl } from '../../common/utils';
import { ALBUMS } from '../../consts/photographyConsts';

const createController: CreateControllerFn = async (props: any) => {
  const { controllerConfig: config, appData, flowAPI } = props;
  const {
    wixCodeApi,
    appParams: { instance },
  } = config;
  const { storeManager, dataFetcher } = appData;
  const storeApi = storeManager.getStoreApi(wixCodeApi, config.compId);
  const consentPolicy = storeManager.getConsentPolicy(wixCodeApi);
  const { environment } = flowAPI;
  const isViewer = environment.isViewer || environment.isSSR;
  const isSSR = environment.isSSR;
  const baseUrl = getBaseUrl(isViewer);
  const albumData = await dataFetcher.getAlbumData({
    baseUrl,
  });
  return getThankYouWidgetController({
    config,
    storeManager,
    consentPolicy,
    storeApi,
    albumData,
    isSSR,
  });
};

function getThankYouWidgetController({
  config,
  consentPolicy,
  storeApi,
  storeManager,
  albumData,
  isSSR,
}: any) {
  const {
    config: {
      style: { styleParams },
    },
    setProps,
    compId,
    type,
    wixCodeApi,
  } = config;
  // TODO: fedopsLogger
  // const thankyouPageLogger = new ThankyouFedops(context);
  // thankyouPageLogger.reportAppLoadStarted();
  const queryParams = wixCodeApi.location.query;
  return {
    pageReady: () => {
      try {
        const albumsSettings = {
          albumId: albumData.settings.id,
          ...albumData.settings,
        };
        storeManager
          .initStoreWixCodeApi(
            albumsSettings,
            {}, // TODO: remove
            ALBUMS.ALBUMS_APP_DEF_ID,
          )
          .then(({ connectedProviders }: any) => {
            try {
              storeManager.initCartApiIfNeeded(isSSR);
              if (!isSSR) {
                storeManager.initTranslations(setProps);
              }
            } catch (e) {
              console.error(e);
            }
            const props = {
              styleParams,
              storeApi,
              queryParams,
              consentPolicy,
              connectedProviders,
              tryToReportAppLoaded: () => {}, // thankyouPageLogger.tryReportAppLoaded(),
              ...storeManager.getInitalProps(
                { compId, setProps, type, consentPolicy },
                connectedProviders,
                ALBUMS.ALBUMS_APP_DEF_ID,
              ),
            };
            setProps(props);
          });
      } catch (e: any) {
        console.error(e);
      }
    },
    exports: () => {},
  };
}

export default createController;
